// Reboot
img { max-width: 100%; }

.cursor-default { cursor: default !important; }
.cursor-pointer { cursor: pointer !important; }
.all-scroll { cursor: all-scroll; }

.fixed-action-btn {
    position: fixed;
    z-index: 998;
    bottom: 65px;
    right: 10px;

    @media screen and (max-width: 768px) {
        bottom: 5px;
        right: 5px;
    }
}
.waves-effect {
    display: inline-block;
}
.btn-floating {
    width: 50px;
    height: 50px;
    -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),0 4px 15px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 1;
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    margin: 10px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    padding: 0;
    cursor: pointer;
}

.was-validated .form-control:valid,
.was-validated .form-control.is-valid,
.form-control:valid,
.form-control.is-valid {
    background-position: right calc(0.8em + 0.1875rem) center;
}

.custom-control {
    padding-left: 1rem;
    .custom-control-input {
        left: 0;
    }
    .custom-control-label {
        padding-left: 5px;
        &::before,
        &::after {
            left: -1rem;
        }
        &:empty {
            padding-left: 0;
        }
    }
}
.custom-file {
    [type="file"] ~ label.custom-file-label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 70px;
    }
}
.custom-editor {
    .ql-container {
        height: 190px;
    }
}

.input-group > .input-group-validate ~ .input-group-append { height: 100%; }
.input-group-validate {
    position: relative;
    flex: 1;

    input, select { border-radius: 0px; }
    .invalid-feedback,
    .invalid-tooltip {
        position: relative;
        top: initial;
    }

    &:nth-of-type(1) {
        input, select {
            border-top-left-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
        }
    }

    &:nth-last-of-type(1) {
        input, select {
            border-top-right-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
        }
    }
}

.modal-fluid {
    max-width: 100%;
    height: calc(100% - 2rem);
    margin: 1rem 1.75rem;

    .modal-content {
        height: 100%;
    }
}

.progress {
    min-height: 1.6875rem !important;
    min-width: 300px !important;
}